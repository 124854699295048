import useEnvironment from '~/composables/useEnvironment';
import { Environment } from '~/types/Environment';

export default defineNuxtRouteMiddleware((to, from) => {
  const environment = useEnvironment();

  if (environment === Environment.client) {
    if (!localStorage.accessToken) {
      return navigateTo('/auth');
    }
  }
});
